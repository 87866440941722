import isNil from 'lodash/isNil'
import { HandlePlaceOrderValuesV2 } from './../../../../utils/checkout-helpers'
import { getRawPhoneNumber } from '../../../../utils/form-utils'
import { CheckoutFormValues } from '../../types/types'

export const getArgsForSubmit = ({
  values
}: {
  values: CheckoutFormValues
}): HandlePlaceOrderValuesV2['paymentInfo'] => {
  if (!values.customerEmail) {
    return {
      customer: { email: '' }
    }
  }

  // eslint-disable-next-line prettier/prettier
  const postPaymentCommunicationPreferences =
    isNil(values.customerMarketingConsent)
    ? undefined
    : { consentMarketingSubscription: values.customerMarketingConsent }

  return {
    customer: {
      email: values.customerEmail,
      firstName: values.customerFirstName || undefined,
      lastName: values.customerLastName || undefined,
      phone: getRawPhoneNumber(values.customerTel) || undefined
    },
    postPaymentCommunicationPreferences
  }
}
