import { onError } from '@apollo/client/link/error'

const getErrorLink = (config) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let { message, locations, path, extensions } of graphQLErrors) {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    }

    if (networkError) {
      console.log('[Network error]: ', networkError)
    }
  })

export default getErrorLink
