import { useEffect, useMemo } from 'react'

import { DdiMode } from '../../../apollo/generated/OptWebGraphQLOperations'
import { addEventProperties, track } from '@toasttab/do-secundo-analytics'
import { useTabEnabled } from '../../../hooks/tabs/useTabEnabled'
import { usePreauthEnabled } from '../../../hooks/preauth/use-preauth-enabled'
import { useJoinablePartyEnabled } from '../../../hooks/party/use-party-enabled'
import { usePreviewMode } from '../../../hooks/use-preview-mode'
import { useAvailability } from '../../../utils/availability'
import { useRestaurantInfo } from '../../../hooks/restaurant-info/use-restaurant-info'
import { useIsIntlRestaurant } from '../../../hooks/use-is-intl-restaurant'

import {
  useGetPartyMembers,
  useGetPartyMode,
  useGetPartyRefresh
} from '../../PartyQuery/PartyQuery'
import { useSpiAllowed } from '../../../hooks/SPI/useSpiAllowed'
import { useFlag } from '../../FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'

export const useTrackingParams = () => {
  const tabEnabled = useTabEnabled()
  const preauthTabEnabled = usePreauthEnabled()
  const joinablePartyEnabled = useJoinablePartyEnabled()
  const previewMode = usePreviewMode()
  const { orderingAvailable } = useAvailability()
  const { mode } = useGetPartyMode()
  const { members, loading: partyMembersCountLoading } = useGetPartyMembers()
  const { partyRefresh } = useGetPartyRefresh()
  const { data: restaurant } = useRestaurantInfo()
  const isIntl = useIsIntlRestaurant()
  const spiAllowed = useSpiAllowed()
  const spiStrategy = useFlag(LDFlags.OPT_SERVER_SPI_STRATEGY)

  // Side-effect to also add a tracking event as enablement may change over time
  useEffect(() => {
    if (spiAllowed) {
      addEventProperties({ spiEnabled: true })
      track('SPI enabled.')
    } else {
      track('SPI disabled.')
    }
  }, [spiAllowed])

  const country = restaurant?.i18n?.country || null

  const trackingParams = useMemo(() => {
    if (partyMembersCountLoading) {
      return null
    }

    let orderFlow
    if (mode === DdiMode.Opt) {
      if (preauthTabEnabled) {
        orderFlow = 'Pre-auth Tabs'
      } else if (tabEnabled) {
        orderFlow = 'No-auth Tabs'
      } else {
        orderFlow = 'Pay-as-you-go'
      }
    } else {
      orderFlow = 'None'
    }

    return {
      previewMode,
      viewOnlyMenu: !orderingAvailable,
      orderFlow,
      tabEnabled,
      preauthTabEnabled,
      joinablePartiesEnabled: joinablePartyEnabled,
      splitPaymentsEnabled: joinablePartyEnabled,
      orderGuid: partyRefresh?.order?.guid,
      mode,
      multipersonGroup: Boolean(members?.length > 1),
      country,
      isIntl,
      spiEnabled: spiAllowed,
      spiStrategy: spiAllowed
        ? spiStrategy
          ? 'SERVER_SPI'
          : 'CLIENT_SPI'
        : undefined
    }
  }, [
    orderingAvailable,
    previewMode,
    tabEnabled,
    preauthTabEnabled,
    joinablePartyEnabled,
    partyRefresh?.order?.guid,
    mode,
    partyMembersCountLoading,
    members?.length,
    country,
    isIntl,
    spiAllowed,
    spiStrategy
  ])

  useEffect(() => {
    if (!trackingParams) {
      return
    }
    addEventProperties(trackingParams)
  }, [trackingParams])

  return trackingParams
}
