import { useEffect } from 'react'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useCreate_Payment_IntentMutation } from '../../apollo/generated/OptWebGraphQLOperations'
import { useSpiAllowed } from './useSpiAllowed'
import { useIsIncrementalAuthEnabled } from './useIsIncrementalAuthEnabled'

export const useFetchSessionSecret = ({
  checkGuid,
  partyGuid,
  partyMemberGuid,
  memberAuthToken,
  canCreatePaymentIntent,
  requireIncrementalAuth
}: {
  checkGuid?: string | null
  partyGuid?: string
  partyMemberGuid?: string
  memberAuthToken?: string
  canCreatePaymentIntent: boolean
  requireIncrementalAuth: boolean
}) => {
  const incrementalAuthEnabled = useIsIncrementalAuthEnabled()
  const spiAllowed = useSpiAllowed()

  const [createPaymentIntentMutation, { data, loading, error }] =
    useCreate_Payment_IntentMutation()

  useEffect(() => {
    if (
      partyGuid &&
      partyMemberGuid &&
      memberAuthToken &&
      spiAllowed &&
      canCreatePaymentIntent
    ) {
      createPaymentIntentMutation({
        variables: {
          input: {
            checkGuid,
            partyGuid,
            partyMemberGuid,
            memberAuthToken,
            requestIncrementalAuthorization:
              incrementalAuthEnabled && requireIncrementalAuth
          }
        }
      })
    }
  }, [
    createPaymentIntentMutation,
    checkGuid,
    partyGuid,
    partyMemberGuid,
    memberAuthToken,
    spiAllowed,
    canCreatePaymentIntent,
    incrementalAuthEnabled,
    requireIncrementalAuth
  ])

  // @ts-ignore
  const { PaymentIntent, PaymentIntentError } = dataByTypename(
    data?.createPaymentIntent
  )

  return {
    data: PaymentIntent,
    loading,
    error: PaymentIntentError || error
  }
}
