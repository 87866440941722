import { PaymentInfoPaymentFormValues } from './../PaymentInfo/getInitialValues'
import { AuthUser } from './../../AuthProvider/authUser'
import { TipFormValues as TipPaymentFormValues } from './../Tip/getInitialValues'
import { CustomerInfoCheckoutFormValues } from '../CustomerInfo/getInitialValues'
import { CheckoutFormFeatureFlags } from '../checkout-utils'
import {
  OptCheckV2Fragment,
  PreComputedTip,
  RestaurantCreditCardConfig,
  OptCloseoutInput
} from './../../../apollo/generated/OptWebGraphQLOperations'
import { CheckoutMode } from '../checkout-modes'
import { PaymentMethod } from '../../../hooks/place-order/use-available-payment-methods'
import {
  SplitPaymentSwitchValues,
  SplitPaymentMethod
} from '../../SplitPaymentSwitch/SplitPaymentSwitch'
import { SpiPaymentType } from '../../../types/SPI'

/**
 * Centralized definitions of all form keys for payment forms
 */
export enum PaymentFormKeys {
  CREDIT_CARD_TIP = 'creditCardTip',
  CUSTOMER_FIRST_NAME = 'customerFirstName',
  CUSTOMER_LAST_NAME = 'customerLastName',
  CUSTOMER_EMAIL = 'customerEmail',
  CUSTOMER_TEL = 'customerTel',
  CUSTOMER_MARKETING_CONSENT = 'customerMarketingConsent',
  ENCRYPTED_CARD = 'encryptedCard',
  SPI_PAYMENT = 'spiPayment',
  PAYMENT_TYPE = 'paymentType',
  PASSWORD = 'password',
  SAVE_CARD = 'saveCard',
  SAVED_CARD_GUID = 'savedCardGuid',
  SELECTED_PAYMENT = 'selectedPayment',
  COUNTRY = 'country',
  SPI_PAYMENT_TYPE = 'spiPaymentType'
}

/**
 * Combines all checkout form modules
 */
export type CheckoutFormValues = CustomerInfoCheckoutFormValues &
  TipPaymentFormValues &
  PaymentInfoPaymentFormValues &
  SplitPaymentSwitchValues & /**
   * The following parameters are appended
   * during form submission, and not controlled
   * directly through the form inputs directly
   */ {
    /**
     * TODO: Migrate the types below to its own section
     * in order to better distinguish between form-managed and non-form managed properties
     */
    // to be added if account created during payment flow
    customerGuid?: string
    // to be added when attempting to validate guest intended payment amounts
    expectedAmount?: number
    expectedSurchargeAmount?: number
    // additional data to be added when attempting check closeouts
    // to utilize other check details, especially to determine
    // whether paying for an ephemeral/preview check vs an OAPI check
    checkToPayFor?: OptCheckV2Fragment | null
    adInfo?: OptCloseoutInput['adInfo']
    portionsToBePaid?: number
    totalPortions?: number
    click2PayParams?: OptCloseoutInput['click2PayParams']
    googlePayParams?: OptCloseoutInput['googlePayParams']
    pkPaymentToken?: OptCloseoutInput['pkPaymentToken']
    spiPayment?: OptCloseoutInput['spiPayment']
    spiPaymentType?: SpiPaymentType
    spiPaymentData?: OptCloseoutInput['spiPaymentData']
  }

/**
 * Params for determining initial payment values
 */
export interface GetPaymentFormInitialValuesParams {
  preComputedTips: Pick<PreComputedTip, 'value' | 'isDefault' | 'percentV2'>[]
  featureFlags: CheckoutFormFeatureFlags
  user: AuthUser
  mode: CheckoutMode
  paymentMethods: PaymentMethod[]
  creditCardConfig: RestaurantCreditCardConfig
  paymentMethod?: SplitPaymentMethod
  applePayEnabled?: boolean
  country?: string
}

/**
 * Params for determining initial tip values
 */
export interface GetTipInitialValuesParams {
  preComputedTips: Pick<PreComputedTip, 'value' | 'isDefault' | 'percentV2'>[]
  tipEnabled: boolean
}
