import * as React from 'react'
import { useParty } from '../PartyProvider/PartyProvider'
import { useGetSpiSdk } from '../../hooks/SPI/useSpiSdk'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useFetchSessionSecret } from '../../hooks/SPI/useFetchSessionSecret'
import { useFetchSpiToken } from '../../hooks/SPI/useFetchSpiToken'

import { useGetMainCheck, useGetMyPreauthInfo } from '../PartyQuery/PartyQuery'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { useSentry } from 'banquet-runtime-modules'
import { DDIMode } from '../../types/DDIGlobals'

const SpiDataContext = React.createContext<any>({} as any)

export const SpiDataProvider = ({
  children,
  mode
}: {
  children: React.ReactNode
  mode: DDIMode
}) => {
  const {
    optConfig: {
      orderingConfig: { allowTabs }
    }
  } = useDDIGlobals()

  const isPAYG = !allowTabs && mode === DDIMode.OPT

  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()
  const spiSdk = useGetSpiSdk()
  const { mainCheck, partyRefresh } = useGetMainCheck()

  const hasItemsInCart = partyRefresh?.carts.some((cart) => {
    if (cart.order.checks[0]) {
      return cart.order.checks[0].expectedPaymentAmount > 0
    }
    return false
  })

  const hasRound = Boolean(partyRefresh?.order)
  const appliedPreauthInfo = useGetMyPreauthInfo()

  const { restaurantGuid: merchantId } = useRestaurant()

  const spiBearerToken = useFetchSpiToken()
  const { data: paymentIntent, loading } = useFetchSessionSecret({
    checkGuid: isPAYG ? null : mainCheck?.guid,
    partyGuid,
    partyMemberGuid,
    memberAuthToken,
    canCreatePaymentIntent: hasItemsInCart || hasRound,
    requireIncrementalAuth: Boolean(
      !partyRefresh?.incrementAuthData && !appliedPreauthInfo
    )
  })

  const spiData = React.useMemo(
    () => ({
      paymentIntent,
      spiBearerToken,
      spiSdk,
      merchantId,
      loading
    }),
    [paymentIntent, spiBearerToken, spiSdk, merchantId, loading]
  )
  return (
    <SpiDataContext.Provider value={spiData}>
      {children}
    </SpiDataContext.Provider>
  )
}

export const useSpiData = () => React.useContext(SpiDataContext)

export const useInitializeSpi = () => {
  const { spiSdk, merchantId, paymentIntent, spiBearerToken, loading } =
    useSpiData()
  const { captureMessage } = useSentry()
  const { restaurantConfig } = useDDIGlobals()
  const { amexAccepted } = restaurantConfig

  const initializeSpi = React.useCallback(
    (
      id: string,
      onInit: () => void,
      onFailure: () => void,
      onStateChange: (a: any) => void
    ) => {
      let frame: HTMLIFrameElement | undefined
      if (spiSdk && merchantId && paymentIntent && spiBearerToken && !loading) {
        try {
          frame = spiSdk.initialize(
            (initEvent) => {
              if (onInit) {
                onInit()
              }
              onStateChange(initEvent)
              spiSdk.monitor((monitorEvent) => {
                if (monitorEvent.content.selectedPaymentMethod) {
                  onStateChange(monitorEvent)
                }
              })
            },
            () => {
              onFailure()
            },
            {
              domElementId: id,
              merchantId,
              sessionSecret: paymentIntent?.sessionSecret,
              acceptAmex: amexAccepted,
              zipRequired: true,
              oauthToken: spiBearerToken
            }
          )
        } catch (e) {
          captureMessage('Unexpected error initializing SPI')
        }
      }
      return () => {
        try {
          frame && document.removeChild(frame)
        } catch {}
      }

      // In this case, we do not want exhaustive dependencies, as including field/form causes this hook
      // to rerun each time the form changes- something we explicitly do not want
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      merchantId,
      paymentIntent?.sessionSecret,
      spiSdk,
      spiBearerToken,
      amexAccepted,
      loading
    ]
  )

  return { initializeSpi }
}
