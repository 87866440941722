import { FormikConfig, FormikHelpers } from 'formik'
import { AuthUser } from '../../components/AuthProvider/authUser'
import {
  OptCloseoutInput,
  RestaurantCreditCardConfig,
  OptCheckV2Fragment,
  OrderAtTableCustomerInput,
  BrandingInformationInput,
  OptGlobalGiftCard,
  PostPaymentCommunicationPreferences
} from '../../apollo/generated/OptWebGraphQLOperations'
import * as CustomerInfo from '../../components/CheckoutForm/CustomerInfo/CustomerInfo'
import * as PaymentInfo from '../../components/CheckoutForm/PaymentInfo/PaymentInfo'
import * as Tip from '../../components/CheckoutForm/Tip/Tip'
import { hasSufficientFundsV2 } from '../gift-card-helpers'
import {
  CheckoutFormFeatureFlags,
  useFeatureFlagsForCheckoutModules
} from '../../components/CheckoutForm/checkout-utils'
import { CheckoutMode } from '../../components/CheckoutForm/checkout-modes'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import {
  GiftCardContextTypeV2,
  useGiftCard
} from '../../components/GiftCardProvider/GiftCardProvider'
import { useCallback } from 'react'
import { useAuth } from '../../components/AuthProvider/AuthProvider'
import { CheckoutFormValues } from '../../components/CheckoutForm/types/types'
import { SplitPaymentMethod } from '../../components/SplitPaymentSwitch/SplitPaymentMethod.enum'
import { getArgsForSubmit as getGCArgsForSubmit } from '../../components/CheckoutForm/GiftCard/GiftCard'
import { PaymentType } from '../../components/CheckoutForm/PaymentInfo/constants'
import { useTipEnabled } from '../../components/CheckoutForm/Tip/useTipEnabled/useTipEnabled'
import { Opt_GuestCurrencyAccountPaymentInput } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'

/**
 * Decimal to fraction conversion that similar to how our receipt service converts decimals to fractions
 * using a map with hardcoded decimal : fraction values
 */
const fractionMap = Array.from({ length: 19 }, (_, i) => ({
  decimal: (1 / (i + 2)).toFixed(3),
  fraction: `1/${i + 2}`
}))
const getFractionValue = (value: number) => {
  const decimal = value.toFixed(3)
  const match = fractionMap.find(({ decimal: d }) => d === decimal)
  return match?.fraction
}
/**
 * Intermediate state of mutation variables before final execution of mutation
 * post-refactor
 */
export interface HandlePlaceOrderValuesV2 {
  paymentInfo: {
    giftCard?: OptCloseoutInput['giftCard']
    /** @deprecated Will migrate to outer level adjacent to click2PayParams, googlePayParams, etc. */
    pkPaymentToken?: OptCloseoutInput['pkPaymentToken']
    preauthedCardGuid?: OptCloseoutInput['preauthedCardGuid']
    newCardInput?: OptCloseoutInput['newCardInput']
    savedCardInput?: OptCloseoutInput['savedCardInput']
    spiPayment?: OptCloseoutInput['spiPayment']
    tipAmount?: OptCloseoutInput['tipAmount']
    customer?: OrderAtTableCustomerInput
    globalGiftCard?: OptCloseoutInput['globalGiftCard']
    postPaymentCommunicationPreferences?: PostPaymentCommunicationPreferences
    guestCurrencyAccount?: OptCloseoutInput['guestCurrencyAccount']
    spiPaymentData?: OptCloseoutInput['spiPaymentData']
  }
  paymentMethod?: SplitPaymentMethod
  expectedAmount?: OptCloseoutInput['expectedAmount']
  expectedSurchargeAmount?: OptCloseoutInput['expectedSurchargeAmount']
  customerGuid?: OptCloseoutInput['customerGuid']
  password?: CreateAccountInput['password']
  // Arbitrary data that is not in the form, but is
  // required for payment submission
  context?: {
    checkToPayFor?: OptCheckV2Fragment | null
  }
  adInfo?: BrandingInformationInput | undefined | null
  portionsToBePaid?: number | undefined
  totalPortions?: number | undefined
  click2PayParams?: OptCloseoutInput['click2PayParams']
  googlePayParams?: OptCloseoutInput['googlePayParams']
  pkPaymentToken?: OptCloseoutInput['pkPaymentToken']
}

export const getArgsForSubmit = ({
  values,
  creditCardConfig,
  featureFlags,
  giftCardContext,
  mode,
  tipEnabled
}: {
  values: CheckoutFormValues
  creditCardConfig: RestaurantCreditCardConfig
  featureFlags: CheckoutFormFeatureFlags
  giftCardContext: GiftCardContextTypeV2
  mode: CheckoutMode
  tipEnabled: boolean
}): HandlePlaceOrderValuesV2 => {
  const {
    paymentType,
    customerGuid,
    adInfo,
    portionsToBePaid,
    totalPortions,
    click2PayParams,
    googlePayParams,
    pkPaymentToken
  } = values
  const {
    rxGiftCard,
    globalGiftCard,
    guestCurrencyAccount,
    shouldUseGuestCurrencyAccount
  } = giftCardContext || {}
  const checkToPayFor = values.checkToPayFor

  const customerArgs = CustomerInfo.getArgsForSubmit({
    values
  })

  const giftCardArgs =
    giftCardContext && featureFlags
      ? getGCArgsForSubmit({
          giftCardContext
        })
      : {}

  const paymentArgs: HandlePlaceOrderValuesV2['paymentInfo'] =
    PaymentInfo.getArgsForSubmit({
      values,
      mode
    })

  // If SPI values are provided, these be used in-lieu of other payment methods
  // spiPaymentData: passed through for backend-based payment intent confirmation flows
  // spiPayment: passed through for client-based payment intent confirmation flows
  let spiPaymentArgs:
    | Pick<
        HandlePlaceOrderValuesV2['paymentInfo'],
        'spiPayment' | 'spiPaymentData'
      >
    | undefined = undefined
  if (values.spiPaymentData) {
    spiPaymentArgs = {
      spiPaymentData: values.spiPaymentData
    }
  } else if (values.spiPayment) {
    spiPaymentArgs = {
      spiPayment: values.spiPayment
    }
  }
  // Make sure we clear out any competing methods
  if (spiPaymentArgs) {
    paymentArgs.newCardInput = undefined
    paymentArgs.savedCardInput = undefined
    paymentArgs.spiPayment = spiPaymentArgs.spiPayment
    paymentArgs.spiPaymentData = spiPaymentArgs.spiPaymentData
  }

  const tipAmountArgs =
    creditCardConfig &&
    Tip.getArgsForSubmit({
      values,
      tipEnabled,
      mode
    })

  const argsForSubmit: HandlePlaceOrderValuesV2 = (function () {
    // exclude paymentInfo if gift card is sole, sufficient method of payment
    if (
      (rxGiftCard || globalGiftCard || guestCurrencyAccount) &&
      checkToPayFor &&
      hasSufficientFundsV2({
        rxGiftCard,
        globalGiftCard,
        guestCurrencyAccount: shouldUseGuestCurrencyAccount
          ? guestCurrencyAccount
          : undefined,
        tipAmount: tipAmountArgs.tipAmount,
        checkAmount: values.expectedAmount || checkToPayFor.total,
        rxGiftCardTipEnabled: giftCardContext.rxGiftCardTipEnabled
      })
    ) {
      if (giftCardContext.rxGiftCardTipEnabled) {
        return {
          paymentInfo: {
            ...customerArgs,
            ...giftCardArgs,
            ...tipAmountArgs
          }
        }
      } else {
        return {
          paymentInfo: {
            ...customerArgs,
            ...giftCardArgs,
            ...(tipAmountArgs.tipAmount ? paymentArgs : undefined),
            ...tipAmountArgs
          }
        }
      }
    }
    // assume credit card payment
    return {
      paymentInfo: {
        ...customerArgs,
        ...giftCardArgs,
        ...paymentArgs,
        ...tipAmountArgs
      },
      customerGuid,
      adInfo,
      portionsToBePaid,
      totalPortions,
      click2PayParams,
      googlePayParams,
      pkPaymentToken
    }
  })()

  if (paymentType === PaymentType.CREDIT_CARD && values.saveCard) {
    argsForSubmit.password = values.password
  }
  argsForSubmit.paymentMethod = values.paymentMethod
  argsForSubmit.expectedAmount = values.expectedAmount
  argsForSubmit.expectedSurchargeAmount = values.expectedSurchargeAmount
  argsForSubmit.context = {
    checkToPayFor
  }
  return argsForSubmit
}

interface CreateHandleSubmitInput {
  submitFunction: (values: HandlePlaceOrderValuesV2) => Promise<any>
  mode: CheckoutMode
}

/**
 * Function that can be input into the onSubmit
 * prop of a Formik element.
 * Can be input synchronously (manually manipulates submitting status) or asynchronously
 * (waits for promise to resolve/reject for submitting state to go back to false)
 */
type FormatAndSubmitFunction = FormikConfig<CheckoutFormValues>['onSubmit']

export const useCreateHandleSubmit = ({
  submitFunction,
  mode
}: CreateHandleSubmitInput): FormatAndSubmitFunction => {
  const { data } = useRestaurantInfo()
  const { creditCardConfig } = data ?? {}
  const giftCardContext = useGiftCard()
  const featureFlags = useFeatureFlagsForCheckoutModules()
  const tipEnabled = useTipEnabled(creditCardConfig)

  return useCallback(
    async (values, { setSubmitting }) => {
      if (!creditCardConfig) {
        throw new Error('Missing required data to perform payment')
      }
      try {
        setSubmitting(true)
        const submitArgs = getArgsForSubmit({
          values,
          mode,
          creditCardConfig,
          giftCardContext,
          featureFlags,
          tipEnabled
        })
        return await submitFunction(submitArgs)
      } finally {
        setSubmitting(false)
      }
    },
    [
      creditCardConfig,
      featureFlags,
      giftCardContext,
      mode,
      tipEnabled,
      submitFunction
    ]
  )
}

/**
 * @deprecated This no longer supports submitting with new accounts; it will just skip that process
 * All this really does now is add the `customerGuid` of the currently logged-in to the request, and fires;
 * we shouldn't even be relying on this input in the first place, but keeping it since I'm not sure
 * if we're (incorrectly) relying upon it in backend for some reason.
 */
export const useCreateHandleSubmitWithNewAccount = ({
  submitFunction,
  mode
}: CreateHandleSubmitInput): {
  /** @deprecated This no longer supports submitting with new accounts; it will just skip that process */
  submitWithCreateAccount: FormatAndSubmitFunction
  /** @deprecated No error happens, now just returns undefined */
  handleCreateAccountError?: Error
} => {
  const { user } = useAuth() as { user: AuthUser | null }
  const currentCustomerGuid = user?.guid

  const handleSubmit = useCreateHandleSubmit({
    submitFunction,
    mode
  })

  const submitWithCreateAccount = useCallback(
    async (
      values: CheckoutFormValues,
      formikBag: FormikHelpers<CheckoutFormValues>
    ) => {
      return handleSubmit(
        { ...values, customerGuid: currentCustomerGuid },
        formikBag
      )
    },
    [currentCustomerGuid, handleSubmit]
  )

  return {
    /** @deprecated This no longer supports submitting with new accounts; it will just skip that process */
    submitWithCreateAccount
  }
}

export const combineModules = (modules: Array<any>, moduleArgs: any) =>
  modules.reduce((acc, moduleFn) => {
    return {
      ...acc,
      ...moduleFn(moduleArgs)
    }
  }, {})

// gets the amount that a guest will have to pay. Due to the different ways
// giftcards and promo cards affect the total before or after tipping, some
// logic is done to determine what the user will actually pay.
//
// For instance, a promo code could completely pay for your item, but not your tip. And
// depending on the restaurant, a giftcard may or may not cover that tip.
export const getAmountDue = (
  canShowTip: boolean,
  expectedPaymentAmount: number,
  rxGiftCardTipEnabled: boolean,
  creditCardTip: number,
  appliedGiftCard?: { expectedAvailableBalance: number },
  globalGiftCard?: OptGlobalGiftCard,
  guestCurrencyAccount?: Opt_GuestCurrencyAccountPaymentInput
): string => {
  const tip = canShowTip ? creditCardTip : 0

  const giftCardTip = rxGiftCardTipEnabled ? tip || 0.0 : 0.0

  const giftCardPayment = Math.min(
    appliedGiftCard?.expectedAvailableBalance || 0.0,
    expectedPaymentAmount + giftCardTip
  )

  const globalGiftCardExpectedAvailableBalance =
    globalGiftCard && globalGiftCard.expectedAvailableBalance
  const globalGiftCardPayment = Math.min(
    globalGiftCardExpectedAvailableBalance || 0.0,
    expectedPaymentAmount + tip - giftCardPayment
  )

  const guestCurrencyAccountPayment = Math.min(
    guestCurrencyAccount?.expectedAvailableBalance ?? 0.0,
    expectedPaymentAmount + tip - giftCardPayment - globalGiftCardPayment
  )

  let amount = expectedPaymentAmount

  const finalAmount =
    amount +
    tip -
    guestCurrencyAccountPayment -
    giftCardPayment -
    globalGiftCardPayment

  return finalAmount > 0.0 ? finalAmount.toFixed(2) : '0.0'
}

// given a SplitPaymentMethod, will indicate whether a SplitCheckPreview is required.
export const shouldUseSplitCheckPreview = (
  paymentMethod?: SplitPaymentMethod
) => {
  return (
    paymentMethod === SplitPaymentMethod.PAY_FOR_SELF ||
    paymentMethod === SplitPaymentMethod.SPLIT_EVENLY
  )
}

export const formatProportion = (value: number) => {
  if (Number.isInteger(value)) {
    return Number(value)
  } else {
    const fractionValue = getFractionValue(value)
    // Return fraction if valid, decimal if undefined aka split is less than 1/20
    return fractionValue ?? value.toFixed(2)
  }
}

// If the user is paying for the balance of the check, and some number of split even portions have already been paid by other party members,
// then from the guest perspective they only paying for the remaining portions and not actually the "full bill"
export const getIsPayingRemainingSplitPortions = ({
  splitPaymentMethod,
  evenSplitPaidPortions
}: {
  splitPaymentMethod: SplitPaymentMethod
  evenSplitPaidPortions: number
}) => {
  return (
    splitPaymentMethod === SplitPaymentMethod.PAY_FOR_PARTY &&
    evenSplitPaidPortions > 0
  )
}
