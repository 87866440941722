/* eslint-disable */
/* Generated by GraphQL Code Generator against local development environment */


      export default {
  "possibleTypes": {
    "CheckSelection": [
      "CheckSelectionGuid",
      "CheckSelectionNoGuid"
    ],
    "CreatePaymentIntentResponse": [
      "PaymentIntent",
      "PaymentIntentError"
    ],
    "Error": [
      "GeneralError",
      "GetMenusError",
      "GetPopularItemsError",
      "ItemFeedbackSurveyError",
      "OPTCartError",
      "OPTOrderError",
      "OPTPartiesLookupError",
      "OPTPartyError",
      "OPTPartyPaymentError",
      "OPTPartyPaymentErrorV2",
      "OPTSplitCheckPaymentError",
      "PayForCheckError",
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "SignUpToLoyaltyError",
      "UpdateBasicInfoError",
      "ValidateApplePayMerchantError"
    ],
    "FeatureFlagResult": [
      "BooleanFeatureFlagResult",
      "NumericFeatureFlagResult",
      "StringFeatureFlagResult"
    ],
    "GetMenusResponseOrError": [
      "GetMenusResponse",
      "GetMenusError"
    ],
    "GetPopularItemsResponse": [
      "PopularItemsV3",
      "GetPopularItemsError"
    ],
    "IncrementAuthorizationResponse": [
      "IncrementalAuthorization",
      "IncrementAuthorizationFailure"
    ],
    "Info": [],
    "ItemFeedbackSurveyResponse": [
      "ItemFeedbackSurvey",
      "ItemFeedbackSurveyError"
    ],
    "ItemLevelFeedbackSubmissionsStatus": [
      "ItemLevelFeedbackSubmissionSuccess",
      "ItemLevelFeedbackSubmissionFailure"
    ],
    "MDSCustomServerNotificationResponse": [
      "MDSServerNotificationResponse",
      "OPTPartyError"
    ],
    "OPTCartResponse": [
      "OPTCart",
      "OPTCartError"
    ],
    "OPTCheckV2": [
      "OPTCheckV2Guid",
      "OPTCheckV2NoGuid",
      "OPTSplitCheckPreview"
    ],
    "OPTCloseoutResponse": [
      "OPTPartyPaymentResponse",
      "OPTPartyPaymentErrorV2",
      "OPTSplitCheckPaymentError"
    ],
    "OPTOrder": [
      "OPTOrderGuid",
      "OPTOrderNoGuid"
    ],
    "OPTPartiesLookupResponse": [
      "OPTParties",
      "OPTPartiesLookupError"
    ],
    "OPTPartyCartResponse": [
      "OPTCart",
      "OPTPartyError"
    ],
    "OPTPartyRefreshResponseV2": [
      "OPTPartyRefreshV2",
      "OPTPartyError"
    ],
    "OPTPartyResponseV2": [
      "OPTPartyV2",
      "OPTPartyError"
    ],
    "OPTPartyStubResponse": [
      "OPTPartyStub",
      "OPTPartyError"
    ],
    "OPTSplitCheckResponse": [
      "OPTSplitCheckPreview",
      "OPTPartyError"
    ],
    "PartyMemberResponse": [
      "OPTPartyMemberV2",
      "OPTPartyError"
    ],
    "PayForCheckResponseOrError": [
      "PayForCheckResponse",
      "PayForCheckError"
    ],
    "PlaceApplePayOrderResponse": [
      "PlaceOrderResponse",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderCartUpdatedError"
    ],
    "PlaceOPTOrderResponse": [
      "OPTPayForCheckResponse",
      "OPTOrderError"
    ],
    "RestaurantOrError": [
      "Restaurant",
      "GeneralError"
    ],
    "SignUpToLoyaltyResponseOrError": [
      "SignUpToLoyaltyResponse",
      "SignUpToLoyaltyError"
    ],
    "UpdateBasicInfoResponseOrError": [
      "UpdateBasicInfoResponse",
      "UpdateBasicInfoError"
    ],
    "UpdatePaymentIntentResponse": [
      "UpdatePaymentIntentSuccess",
      "PaymentIntentError"
    ],
    "ValidateApplePayMerchantResponse": [
      "ValidateApplePayMerchantSuccessResponse",
      "ValidateApplePayMerchantError"
    ],
    "Warning": [
      "PlaceOrderWarning"
    ]
  }
}
    
// Generated on 2025-02-07T13:58:21-05:00
