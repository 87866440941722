import * as React from 'react'
import { orderRequest$ } from 'cornucopia-apis'
import { OrderRequest } from './OrderListener.types'
import { useSentry } from 'banquet-runtime-modules'
import { ResponseTypes } from '../cornucopia.types'
import { toastError } from '../ListenerError'
import { useHandleFirePartyTabRound } from '../../../../client/hooks/place-order/use-fire-party-round'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { useGetPartyRefresh } from '../../../../client/components/PartyQuery/PartyQuery'
import { useOpt_Increment_Auth_And_FireMutation } from '../../../../client/apollo/generated/OptWebGraphQLOperations'

import { useParty } from '../../../../client/components/PartyProvider/PartyProvider'
import { useIsIncrementalAuthEnabled } from '../../../../client/hooks/SPI/useIsIncrementalAuthEnabled'

export const OrderListener = () => {
  const { captureMessage } = useSentry()
  const { partyRefresh } = useGetPartyRefresh()
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  const incrementalAuthEnabled = useIsIncrementalAuthEnabled()
  const [firePartyRound, { data, error, loading }] =
    useHandleFirePartyTabRound()

  const [incrementAuthAndFirePartyRound] =
    useOpt_Increment_Auth_And_FireMutation()
  const responseRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (!responseRef.current || loading) return
    if (error) {
      toastError(
        'An error occurred attempting to fire order, please try again.'
      )
      captureMessage(error.message, 'warning')
      responseRef.current.next({
        kind: ResponseTypes.ERROR,
        message: 'An error has occurred',
        code: '400'
      })
      responseRef.current = null
      return
    }

    const { OPTPartyRefreshV2 } = dataByTypename(data.optPartyRefresh)

    if (OPTPartyRefreshV2) {
      responseRef.current.next({
        kind: ResponseTypes.OK,
        info: [{ code: '200', message: 'Fired party round.' }],
        warnings: []
      })
    }
  }, [data, error, loading, captureMessage])

  React.useEffect(() => {
    const subscription = orderRequest$.subscribe(
      async (request: OrderRequest) => {
        if (
          typeof firePartyRound !== 'function' ||
          Boolean(incrementalAuthEnabled && partyRefresh?.incrementAuthData)
        ) {
          return
        }
        const { response$ } = request.header
        try {
          responseRef.current = response$
          await firePartyRound()
        } catch (error: any) {
          toastError(
            'An error occurred attempting to fire order, please try again.'
          )

          captureMessage(error.message, 'warning')
          response$.next({
            kind: ResponseTypes.ERROR,
            message: 'Unknown error',
            code: '400'
          })
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [
    firePartyRound,
    captureMessage,
    partyRefresh?.incrementAuthData,
    incrementalAuthEnabled
  ])

  React.useEffect(() => {
    const subscription = orderRequest$.subscribe(
      async (request: OrderRequest) => {
        if (!partyGuid || !partyMemberGuid || !memberAuthToken) {
          return
        }
        if (!incrementalAuthEnabled) {
          return
        }
        if (
          typeof firePartyRound !== 'function' ||
          !Boolean(partyRefresh?.incrementAuthData)
        ) {
          return
        }
        const { response$ } = request.header
        try {
          responseRef.current = response$

          const res = await incrementAuthAndFirePartyRound({
            variables: {
              incrementAuthAndFire: {
                partyGuid,
                partyMemberGuid,
                memberAuthToken
              }
            }
          })
          const { OPTPartyRefreshV2, OPTPartyError } = dataByTypename(
            res.data?.optIncrementAuthAndFire
          )
          if (OPTPartyRefreshV2) {
            request.header.response$.next({
              kind: ResponseTypes.OK,
              info: [{ code: '200', message: 'Incremented auth and fired' }],
              warnings: []
            })
          }

          if (OPTPartyError) {
            toastError(
              'Payment Error - Your order was not submitted, try again or navigate back to the Menu, and Checkout.'
            )
            response$.next({
              kind: ResponseTypes.ERROR,
              message: 'Unknown error',
              code: '400'
            })
          }
        } catch (error: any) {
          toastError(
            'An error occurred attempting to fire order, please try again.'
          )

          captureMessage(error.message, 'warning')
          response$.next({
            kind: ResponseTypes.ERROR,
            message: 'Unknown error',
            code: '400'
          })
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [
    firePartyRound,
    captureMessage,
    partyRefresh?.incrementAuthData,
    incrementalAuthEnabled,
    incrementAuthAndFirePartyRound,
    partyGuid,
    partyMemberGuid,
    memberAuthToken
  ])

  return <></>
}
