import { CheckoutMode } from '../../checkout-modes'
import { SelectedPayment } from '../constants'

export const getArgsForSubmit = ({ values, mode }) => {
  switch (mode) {
    case CheckoutMode.CREATE_TAB_MODE:
      return {}
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE:
      return {
        newCardInput: {
          ...values.encryptedCard,
          saveCard: values.saveCard
        }
      }
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE:
    default:
      switch (values.selectedPayment) {
        case SelectedPayment.SAVED_CARD:
          return {
            savedCardInput: {
              cardGuid: values.savedCardGuid
            }
          }
        case SelectedPayment.NEW_CARD:
          return {
            newCardInput: {
              ...values.encryptedCard,
              saveCard: values.saveCard
            }
          }
        default:
          return {}
      }
  }
}
