import React from 'react'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { useDDIGlobals } from '../../DDIGlobalsProvider/DDIGlobalsProvider'
import { DDIMode } from '../../../types/DDIGlobals'
import { useGetTotalsRemaining } from '../../PartyQuery/PartyQuery'
import { useAuth } from '../../AuthProvider/AuthProvider'
import { useGiftCard } from '../../GiftCardProvider/GiftCardProvider'
import { useRestaurant } from '../../RestaurantProvider/RestaurantProvider'
import { useGuestWalletLazyQuery } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import ToastCashImg from '../../../assets/toast-cash.svg'

const GuestCurrencyToggleCheckbox = (props: {
  shouldUseToastCash: boolean
  setShouldUseToastCash: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <div>
      <CheckboxField
        id={'toast-cash-checkbox'}
        name={'should-use-toast-cash'}
        onChange={(e) => {
          props.setShouldUseToastCash(e.target.checked)
        }}
        title={'Toast Cash'}
        label={
          /* All of this styling inside the label is to get it to look like the other SPI elements */
          <div className='inline-flex items-center gap-x-2.5 pl-0.5 -translate-y-1'>
            <ToastCashImg />
            <div className='font-bold text-sm font'>Toast Cash</div>
          </div>
        }
        checked={props.shouldUseToastCash}
      ></CheckboxField>
    </div>
  )
}

/**
 * Handles fetching of Toast Cash balance + synchronization with the payment context state
 * Includes opinionated styling for usage in a SPI context
 */

export const GuestCurrencyToggle = () => {
  const {
    guestCurrencyAccount,
    applyGuestCurrencyAccount,
    removeGuestCurrencyAccount,
    rxGiftCard,
    rxGiftCardEnabled,
    globalGiftCard
  } = useGiftCard()
  const { mode } = useDDIGlobals()
  const { remainingTotal } = useGetTotalsRemaining()
  const balanceDue =
    remainingTotal -
    (globalGiftCard?.expectedAvailableBalance ?? 0.0) -
    (rxGiftCardEnabled ? rxGiftCard?.expectedAvailableBalance ?? 0.0 : 0.0)
  const hasCustomer = Boolean(useAuth().authenticated)
  const availableBalance = guestCurrencyAccount?.expectedAvailableBalance
  const isValidPaymentContext =
    mode === DDIMode.STP && balanceDue >= 0 && hasCustomer
  const [checked, setChecked] = React.useState(isValidPaymentContext)
  React.useEffect(() => {
    if (typeof availableBalance !== 'number' || !hasCustomer) {
      removeGuestCurrencyAccount()
      return
    }
    // If payment context validity changes, then clear out
    if (!isValidPaymentContext) {
      removeGuestCurrencyAccount()
    } else {
      applyGuestCurrencyAccount()
    }
    setChecked(isValidPaymentContext)
  }, [
    availableBalance,
    isValidPaymentContext,
    hasCustomer,
    applyGuestCurrencyAccount,
    removeGuestCurrencyAccount
  ])

  if (!availableBalance || !isValidPaymentContext) {
    return null
  }

  return (
    <div data-testid='toast-cash-toggle' className='px-2 pt-2'>
      <div className='border-b border-solid border-b-[#d3d3d3] border-t-0 border-r-0 border-l-0'>
        <GuestCurrencyToggleCheckbox
          setShouldUseToastCash={(checked) => {
            if (checked) {
              applyGuestCurrencyAccount()
            } else {
              removeGuestCurrencyAccount()
            }
            setChecked(checked)
          }}
          shouldUseToastCash={checked}
        />
      </div>
    </div>
  )
}

/**
 * Allows to re-hydrate the state of the guest wallet. Guards in cases where a guest account is not present.
 */
export const useRefreshGuestCurrencyAccount = () => {
  const hasCustomer = Boolean(useAuth().authenticated)
  const { restaurantGuid } = useRestaurant()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { refetch }] = useGuestWalletLazyQuery()
  const refetchCallback = React.useCallback(() => {
    if (refetch) {
      refetch({ restaurantId: restaurantGuid }).catch(() => {
        // swallow errors
      })
    }
  }, [restaurantGuid, refetch])
  if (!hasCustomer) {
    return () => {}
  }
  return refetchCallback
}
