import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { DDIMode } from '../../types/DDIGlobals'

export const useSpiAllowed = () => {
  const spiEnabled = useFlag(LDFlags.USE_SPI)
  const incrementalAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const {
    mode,
    optConfig: {
      paymentConfig: { allowPreauth }
    }
  } = useDDIGlobals()

  if (!spiEnabled) {
    return false
  }

  if (mode === DDIMode.MNP || mode === DDIMode.STP) {
    return true
  }

  if (mode === DDIMode.OPT) {
    return allowPreauth ? incrementalAuthEnabled : spiEnabled
  }

  // For other modes, we don't really know what to do. So let's not support this.
  return false
}
