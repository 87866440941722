import {
  NewCardInput,
  OptCheckV2Fragment,
  OptPartyPaymentErrorV2,
  SavedCardInput
} from './../../../apollo/generated/OptWebGraphQLOperations'
import {
  OptOrderGuidFragment,
  PlaceOrderWarning
} from '../../../apollo/generated/OptWebGraphQLOperations'
import { useEffect, useRef, useState, useCallback } from 'react'
import get from 'lodash/get'
import { RefetchQueriesFunction } from '@apollo/client'

import {
  Customer_Credit_CardsDocument,
  Opt_Get_Party_RefreshDocument,
  useParty_CloseoutMutation,
  usePlace_Payg_OrderMutation,
  useSplit_Check_CloseoutMutation,
  Place_Payg_OrderMutationOptions,
  Split_Check_CloseoutMutationOptions,
  Party_CloseoutMutationOptions
} from '../../../apollo/generated/OptWebGraphQLOperations'
import { useGetSelectionsToSplit } from './../../use-get-selections-to-split'
import { useAfterCheckout } from './use-after-checkout'
import { useDDIGlobals } from '../../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useGiftCard } from '../../../components/GiftCardProvider/GiftCardProvider'
import { useParty } from '../../../components/PartyProvider/PartyProvider'
import { usePreauthEnabled } from '../../preauth/use-preauth-enabled'
import { useGetTab } from '../../../components/TabQuery/TabQuery'
import {
  HandlePlaceOrderValuesV2,
  shouldUseSplitCheckPreview
} from '../../../utils/checkout-helpers'
import { useAuth } from '../../../components/AuthProvider/AuthProvider'
import { SplitPaymentMethod } from '../../../components/SplitPaymentSwitch/SplitPaymentMethod.enum'
import { useGetPartyMember } from '../../../components/PartyQuery/PartyQuery'
import { useGuestInfo } from '../../use-guest-info'
import { Maybe } from 'graphql/jsutils/Maybe'

export enum OrderFlow {
  PAYG = 'PAYG',
  TABS = 'TABS'
}

export enum CheckoutType {
  PAYG = 'PAYG',
  PARTY = 'PARTY',
  SPLIT = 'SPLIT'
}

export enum LoyaltyMode {
  earned = 'earned',
  signup = 'signup',
  none = 'none'
}

interface GenericError {
  message?: string
}

type HandleOnCompletedCombined =
  | Place_Payg_OrderMutationOptions['onCompleted']
  | Party_CloseoutMutationOptions['onCompleted']
  | Split_Check_CloseoutMutationOptions['onCompleted']

/**
 *
 * Handles all OPT checkout scenarios, and
 * distinguishes mutation based on orderFlow argument
 */
const useGetCloseoutMutation = (
  completedOrderCallback: (
    completedOrder: OptOrderGuidFragment,
    warnings: PlaceOrderWarning[],
    checkoutType: CheckoutType,
    checkGuid: string,
    loyaltyMode: LoyaltyMode
  ) => void,
  errorMessageCallback: (error: GenericError) => void,
  refetchQueriesCallback: RefetchQueriesFunction
) => {
  const me = useGetPartyMember()
  const handleOnCompleted =
    (checkoutType: CheckoutType): HandleOnCompletedCombined =>
    (data) => {
      if (
        data.optPartyCloseout &&
        data.optPartyCloseout.__typename === 'OPTPartyPaymentResponse'
      ) {
        const warnings = data.optPartyCloseout.warnings
        const responseCompletedOrder = data.optPartyCloseout.partyRefresh.order
        // On a successful OPTPartyPaymentResponse, we are quite certain that
        // an order will be on the partyRefresh object
        if (responseCompletedOrder) {
          const claimedCheckGuids =
            data.optPartyCloseout.partyRefresh.party.members.find(
              (member) => member.partyMemberGuid === me?.partyMemberGuid
            )?.claimedCheckGuids
          const lastCheckIndex = claimedCheckGuids
            ? claimedCheckGuids.length - 1
            : 0

          const checkGuid = claimedCheckGuids
            ? claimedCheckGuids[lastCheckIndex]
            : responseCompletedOrder.checkGuid

          let loyaltyMode = LoyaltyMode.none
          if (data.optPartyCloseout.loyaltySignupPossible) {
            if (data.optPartyCloseout.hasLoyalty) {
              loyaltyMode = LoyaltyMode.earned
            } else {
              loyaltyMode = LoyaltyMode.signup
            }
          }

          completedOrderCallback(
            responseCompletedOrder,
            warnings,
            checkoutType,
            checkGuid,
            loyaltyMode
          )
        }
      } else if (
        data.optPartyCloseout.__typename === 'OPTPartyPaymentErrorV2' ||
        data.optPartyCloseout.__typename === 'OPTSplitCheckPaymentError'
      ) {
        errorMessageCallback(data.optPartyCloseout)
      }
    }

  // Note: This logic is very repetitious, but only guaranteed way for type safety
  const options = {
    onError: errorMessageCallback,
    refetchQueries: refetchQueriesCallback
  }
  const [placePaygOrderMutation] = usePlace_Payg_OrderMutation({
    onCompleted: handleOnCompleted(CheckoutType.PAYG),
    ...options
  })
  const [partyTabCloseoutMutation] = useParty_CloseoutMutation({
    onCompleted: handleOnCompleted(CheckoutType.PARTY),
    ...options
  })
  const [splitCheckCloseoutMutation] = useSplit_Check_CloseoutMutation({
    onCompleted: handleOnCompleted(CheckoutType.SPLIT),
    ...options
  })

  return useCallback(
    ({
      orderFlow,
      paymentMethod,
      check
    }: {
      orderFlow: OrderFlow
      paymentMethod?: SplitPaymentMethod
      check?: OptCheckV2Fragment | null
    }) => {
      const hasCheckGuid = Boolean(
        check?.__typename === 'OPTCheckV2Guid' ? check.guid : undefined
      )
      if (orderFlow === OrderFlow.TABS) {
        if (hasCheckGuid) {
          return partyTabCloseoutMutation
        }
        if (shouldUseSplitCheckPreview(paymentMethod)) {
          return splitCheckCloseoutMutation
        } else {
          return partyTabCloseoutMutation
        }
      } else {
        return placePaygOrderMutation
      }
    },
    [
      partyTabCloseoutMutation,
      placePaygOrderMutation,
      splitCheckCloseoutMutation
    ]
  )
}

interface UsePlaceOrderParams {
  onCompleted?(completedOrder: OptOrderGuidFragment): void
  onError?(error: OptPartyPaymentErrorV2 | GenericError): void
  onWarnings?(warning: PlaceOrderWarning[]): void
  orderFlow: OrderFlow
}
const defaultParams: Required<UsePlaceOrderParams> = {
  onCompleted() {},
  onError() {},
  onWarnings() {},
  orderFlow: OrderFlow.PAYG
} as const

defaultParams.onCompleted = () => {}

export const usePlaceOrder = ({
  onCompleted = defaultParams.onCompleted,
  onError = defaultParams.onError,
  onWarnings = defaultParams.onWarnings,
  orderFlow = defaultParams.orderFlow
}: UsePlaceOrderParams = defaultParams) => {
  const { restaurantGuid } = useDDIGlobals()
  const { authenticated } = useAuth()
  const [errorMessage, setErrorMessage] = useState<
    OptPartyPaymentErrorV2 | GenericError | undefined
  >(undefined)
  const [completedOrder, setCompletedOrder] = useState<
    OptOrderGuidFragment | undefined
  >(undefined)

  const {
    rxGiftCard,
    globalGiftCard,
    guestCurrencyAccount,
    shouldUseGuestCurrencyAccount
  } = useGiftCard()
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  const preauthEnabled = usePreauthEnabled()
  const { primaryCheck } = useGetTab()
  const preauthGuid = preauthEnabled && primaryCheck?.appliedPreauthInfo?.guid
  const isNewCardValid = (card: Maybe<NewCardInput>): boolean => {
    return Boolean(card && card.encryptedCardDataString && card.encryptionKeyId)
  }
  const isSavedCardValid = (card: Maybe<SavedCardInput>): boolean => {
    return Boolean(card && card.cardGuid)
  }

  // avoids depending on consumer to stabilize onError callback
  const onErrorRef = useRef(onError)
  onErrorRef.current = onError
  // handle error side-effects
  useEffect(() => {
    if (errorMessage) {
      onErrorRef.current(errorMessage)
    }
  }, [errorMessage])

  const refetchQueries: RefetchQueriesFunction = useCallback(() => {
    const toRefetch: ReturnType<RefetchQueriesFunction> = [
      {
        query: Opt_Get_Party_RefreshDocument,
        variables: { partyGuid, partyMemberGuid, memberAuthToken }
      }
    ]

    // Needed in case user saved a new card or address.
    if (authenticated) {
      toRefetch.push({
        query: Customer_Credit_CardsDocument
      })
    }

    return toRefetch
  }, [authenticated, memberAuthToken, partyGuid, partyMemberGuid])

  const afterCheckoutCallback = useAfterCheckout()
  const getCloseoutMutation = useGetCloseoutMutation(
    (completedOrder, warnings, checkoutType, checkGuid, hasLoyalty) => {
      setCompletedOrder(completedOrder)
      onCompleted(completedOrder)
      onWarnings(warnings)
      afterCheckoutCallback(completedOrder, checkoutType, checkGuid, hasLoyalty)
    },
    setErrorMessage,
    refetchQueries
  )

  const selectionsToSplit = useGetSelectionsToSplit()

  const { updateGuestInfoFromCustomer } = useGuestInfo()
  return {
    async placeOrder(values: HandlePlaceOrderValuesV2) {
      const paymentInfo = values.paymentInfo

      const finalMutation = getCloseoutMutation({
        orderFlow,
        paymentMethod: values.paymentMethod,
        check: values.context?.checkToPayFor
      })

      try {
        // only use preauthed card if user actually selected it
        if (
          preauthGuid &&
          paymentInfo.savedCardInput &&
          preauthGuid === paymentInfo.savedCardInput.cardGuid
        ) {
          paymentInfo.preauthedCardGuid = preauthGuid
          paymentInfo.newCardInput = null
          paymentInfo.savedCardInput = null
        }

        if (!isNewCardValid(paymentInfo.newCardInput)) {
          paymentInfo.newCardInput = null
        }

        if (!isSavedCardValid(paymentInfo.savedCardInput)) {
          paymentInfo.savedCardInput = null
        }

        if (!restaurantGuid) {
          throw new Error('Payment failed: restaurantGuid undefined')
        }

        const checkToPayFor = values.context?.checkToPayFor
        const checkGuid =
          checkToPayFor?.__typename === 'OPTCheckV2Guid'
            ? checkToPayFor.guid
            : undefined

        updateGuestInfoFromCustomer({
          email: values.paymentInfo.customer?.email,
          firstName: values.paymentInfo.customer?.firstName ?? undefined,
          lastName: values.paymentInfo.customer?.lastName ?? undefined,
          phone: values.paymentInfo.customer?.phone ?? undefined
        })

        return finalMutation({
          variables: {
            closeoutInput: {
              restaurantGuid,
              partyGuid: partyGuid || '',
              partyMemberGuid: partyMemberGuid || '',
              memberAuthToken: memberAuthToken || '',
              pkPaymentToken:
                values.pkPaymentToken || paymentInfo.pkPaymentToken,
              preauthedCardGuid: paymentInfo.preauthedCardGuid,
              newCardInput: paymentInfo.newCardInput,
              savedCardInput: paymentInfo.savedCardInput,
              spiPayment: paymentInfo.spiPayment,
              // TODO: ensure this is only included for unverified customers
              customerGuid: values.customerGuid,
              customer: {
                email: values.paymentInfo.customer?.email || '',
                firstName: values.paymentInfo.customer?.firstName || '',
                lastName: values.paymentInfo.customer?.lastName || '',
                phone: values.paymentInfo.customer?.phone || ''
              },
              tipAmount: values.paymentInfo.tipAmount || 0.0,
              giftCard: rxGiftCard
                ? {
                    cardNumber: rxGiftCard.cardNumber,
                    expectedAvailableBalance:
                      rxGiftCard.expectedAvailableBalance
                  }
                : undefined,
              globalGiftCard: globalGiftCard
                ? {
                    cardIdentifier: globalGiftCard.cardIdentifier,
                    expectedAvailableBalance:
                      globalGiftCard.expectedAvailableBalance
                  }
                : undefined,
              guestCurrencyAccount: shouldUseGuestCurrencyAccount
                ? guestCurrencyAccount
                : undefined,
              checkGuid,
              selectionsToSplit:
                shouldUseSplitCheckPreview(values.paymentMethod) &&
                orderFlow === OrderFlow.TABS
                  ? selectionsToSplit
                  : null,
              expectedAmount: values.expectedAmount,
              expectedSurchargeAmount: values.expectedSurchargeAmount,
              // the bff is used to issue credit rewards, so we do not need to pass ad info when the ad is display only
              adInfo:
                values.adInfo?.adType === 'display' ? undefined : values.adInfo,
              portionsToBePaid: values.portionsToBePaid,
              totalPortions: values.totalPortions,
              click2PayParams: values.click2PayParams,
              googlePayParams: values.googlePayParams,
              postPaymentCommunicationPreferences:
                values.paymentInfo.postPaymentCommunicationPreferences,
              spiPaymentData: values.paymentInfo.spiPaymentData
            }
          }
        })
      } catch (error) {
        let message: string = 'An unknown error occurred.'
        const errorMessage = get(error, 'message', undefined)
        if (typeof errorMessage === 'string') {
          message = errorMessage
        }
        console.error(message)
        setErrorMessage({ message })
      }
    },
    error: errorMessage,
    completedOrder,
    resetError() {
      setErrorMessage(undefined)
    }
  }
}
