import * as React from 'react'
import { useLocation } from 'react-router'
import { PartyVisibility } from '@local/cornucopia/src/PartyListener/PartyListener.types'
import { useInitializeParty, useParty } from '../PartyProvider/PartyProvider'
import { LocationWithState } from '../../hooks/use-handle-rejoin'
import { useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import { useTab } from '../TabProvider/TabProvider'
import { TabClosed } from '../TabClosed/TabClosed'
import { LoadingModal } from '../OPTPartySplashProvider/OPTPartySplash'
import { useGetOrderFromParty } from '../PartyQuery/PartyQuery'
import { useIsIncrementalAuthEnabled } from '../../hooks/SPI/useIsIncrementalAuthEnabled'

export const PartyInitialization = () => {
  const party = useParty()
  const { updatePartyProperties, partyGuid } = party
  const location: LocationWithState = useLocation()
  const { loading, partyRefresh } = useGetPartyRefresh()
  const { deleteTab } = useTab()

  const { primaryCheck, loading: loadingOrder } = useGetOrderFromParty()

  const incrementalAuthEnabled = useIsIncrementalAuthEnabled()

  const redirectProperties = location.state?.partyProperties

  const [initialize, { error }] = useInitializeParty(null, false, null, {
    onCompleted: () => {}
  })

  const onTabClosed = () => {
    deleteTab()
    party.deletePartyProperties()
  }

  React.useEffect(() => {
    if (
      redirectProperties?.partyGuid &&
      redirectProperties?.partyMemberGuid &&
      redirectProperties?.memberAuthToken
    ) {
      if (!redirectProperties.pin) {
        redirectProperties.pin = null
      }
      updatePartyProperties(redirectProperties)
      delete location.state.partyProperties
    } else if (!error && !partyGuid) {
      initialize({
        name: undefined,
        phone: undefined,
        visibility: PartyVisibility.PRIVATE
      })
    }
  }, [
    updatePartyProperties,
    initialize,
    redirectProperties,
    location.state,
    error,
    partyGuid
  ])

  if (loading || loadingOrder) {
    return <LoadingModal />
  }

  const isActuallyClosed =
    partyRefresh?.incrementAuthData?.lastIncrement &&
    primaryCheck?.paymentStatus !== 'OPEN'

  const isClosed = incrementalAuthEnabled
    ? isActuallyClosed
    : (primaryCheck && primaryCheck.isClosed) || false

  if (!location.pathname.includes('confirm') && isClosed) {
    return <TabClosed onClose={onTabClosed} />
  }

  return <></>
}
