import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { DDIMode } from '../../types/DDIGlobals'

export const useIsIncrementalAuthEnabled = () => {
  const incrementalAuthEnabled = useFlag(LDFlags.INCREMENTAL_AUTH)
  const {
    mode,
    optConfig: {
      paymentConfig: { allowPreauth }
    }
  } = useDDIGlobals()

  return incrementalAuthEnabled && allowPreauth && mode === DDIMode.OPT
}
