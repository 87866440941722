import * as React from 'react'
import { preauthRequest$ } from 'cornucopia-apis'
import { useSentry } from 'banquet-runtime-modules'
import { Subject } from 'rxjs'
import { v4 as uuid } from 'uuid'
import { useHandleAddPreauthAndFire } from '../../../../client/hooks/tabs/use-add-preauth-and-fire'
import { getRawPhoneNumber } from '../../../../client/utils/form-utils'
import { useHandleApplePayPayment } from '../../../../client/hooks/apple-pay/useHandleApplePayPayment'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { CheckoutMode } from '../../../../client/components/CheckoutForm/checkout-modes'
import { HandlePlaceOrderValuesV2 } from '../../../../client/utils/checkout-helpers'
import { PreauthRequest, PreauthResponse } from './CheckoutListener.types'
import { ResponseTypes } from '../cornucopia.types'
import { toastError } from '../ListenerError'
import { getErrorMsg } from './CheckoutListener.helper'
import { useSpiData } from '../../../../client/components/SPI/SpiDataProvider'
import { useDDIGlobals } from '../../../../client/components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useParty } from '../../../../client/components/PartyProvider/PartyProvider'
import {
  ConfirmPaymentResultEvent,
  TokenizeResultEvent
} from '../../../../client/types/SPI'
import {
  useAdd_Inc_Auth_And_FireMutation,
  useUpdate_Payment_IntentMutation
} from '../../../../client/apollo/generated/OptWebGraphQLOperations'
import { useLocation } from 'react-router'

export const CheckoutListener = () => {
  const location = useLocation()
  const { captureMessage } = useSentry()
  const [addPreauthAndFire] = useHandleAddPreauthAndFire()
  const [incAuthAndFire] = useAdd_Inc_Auth_And_FireMutation()
  const [
    updatePaymentIntentMutation,
    { data: updateIntentData, loading: updateIntentLoading }
  ] = useUpdate_Payment_IntentMutation()
  const {
    initApplePayPayment,
    error: applePayError,
    loading: applePayLoading,
    response: applePayResponse
  } = useHandleApplePayPayment({
    submitFunction: addPreauthAndFire as (
      values: HandlePlaceOrderValuesV2
    ) => Promise<any>,
    mode: CheckoutMode.CREATE_PREAUTH_TAB_MODE
  })

  const { spiSdk, paymentIntent } = useSpiData()
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()
  const { restaurantGuid } = useDDIGlobals()
  const [applePayFired, setApplePayFired] = React.useState<boolean>(false)
  const response$Ref = React.useRef<Subject<PreauthResponse>>()

  // credit card preauth request
  React.useEffect(() => {
    const subscription = preauthRequest$.subscribe(
      async (request: PreauthRequest) => {
        if (typeof addPreauthAndFire !== 'function') return
        if (request.checkoutInfo.paymentMethod === 'Credit Card') {
          try {
            const {
              email,
              firstName,
              lastName,
              phone,
              encryptedCard,
              saveCard,
              selectedCard
            } = request.checkoutInfo
            const customer = {
              email: email,
              phone: getRawPhoneNumber(phone),
              firstName: firstName,
              lastName: lastName
            }

            // if used a saved card (selectedCard) vs. new card
            const res = await addPreauthAndFire({
              paymentInfo: {
                customer,
                savedCardInput: selectedCard
                  ? { cardGuid: selectedCard }
                  : null,
                newCardInput: selectedCard
                  ? null
                  : { ...encryptedCard, saveCard }
              }
            })

            const { OPTPartyError, OPTPartyRefreshV2 } = dataByTypename(
              res.data?.optAddPreauthAndFire
            )

            if (OPTPartyError) {
              const { code } = OPTPartyError
              const errorMsg = getErrorMsg(code)

              toastError(errorMsg)

              captureMessage(OPTPartyError.message, 'warning', {
                captureContext: (scope: any) =>
                  scope.setTags({ source: 'corn-tabs-request' })
              })

              request.header.response$.next({
                kind: ResponseTypes.ERROR,
                message: 'An error has occurred',
                code
              })
            }

            if (OPTPartyRefreshV2) {
              request.header.response$.next({
                kind: ResponseTypes.OK,
                info: [{ code: '200', message: 'Paid with Credit Card' }],
                warnings: []
              })
            }
          } catch (err: any) {
            toastError('Something went wrong.')
            captureMessage(err.message, 'warning', {
              captureContext: (scope: any) =>
                scope.setTags({ source: 'corn-tabs-request' })
            })

            request.header.response$.next({
              kind: ResponseTypes.ERROR,
              message: 'An error has occurred',
              code: ''
            })
          }
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [addPreauthAndFire, captureMessage])

  // apple pay preauth request
  React.useEffect(() => {
    const subscription = preauthRequest$.subscribe(
      (request: PreauthRequest) => {
        if (request.checkoutInfo.paymentMethod === 'Apple Pay') {
          initApplePayPayment({ paymentInfo: {} })
          setApplePayFired(true)
          response$Ref.current = request.header.response$
        }
      }
    )
    return () => {
      subscription.unsubscribe()
    }
  }, [initApplePayPayment])

  React.useEffect(() => {
    if (
      !partyGuid ||
      !partyMemberGuid ||
      !memberAuthToken ||
      !paymentIntent?.sessionSecret ||
      !restaurantGuid
    ) {
      return
    }
    if (location.pathname.includes('tab-management/start')) {
      updatePaymentIntentMutation({
        variables: {
          input: {
            partyGuid,
            partyMemberGuid,
            memberAuthToken,
            paymentIntentId: paymentIntent.sessionSecret.split('_')[1],
            tipAmount: 0
          }
        }
      })
        .then((response) => {
          if (
            response.data?.updatePaymentIntent.__typename !==
            'UpdatePaymentIntentSuccess'
          ) {
            toastError('Something went wrong. Refresh the page and try again.')
          }
        })
        .catch(() => {
          toastError('Something went wrong. Refresh the page and try again.')
        })
    }
  }, [
    partyGuid,
    paymentIntent,
    partyMemberGuid,
    memberAuthToken,
    updatePaymentIntentMutation,
    restaurantGuid,
    location.pathname
  ])

  // incremental auth preauth request
  React.useEffect(() => {
    if (
      !partyGuid ||
      !partyMemberGuid ||
      !memberAuthToken ||
      !paymentIntent?.sessionSecret ||
      !restaurantGuid ||
      updateIntentLoading ||
      !updateIntentData
    ) {
      return
    }
    if (
      updateIntentData.updatePaymentIntent.__typename !==
      'UpdatePaymentIntentSuccess'
    ) {
      return
    }

    const subscription = preauthRequest$.subscribe(
      (request: PreauthRequest) => {
        spiSdk.createPaymentMethod(
          async (paymentMethod: TokenizeResultEvent) => {
            const { billingDetails, card } = paymentMethod.content

            const email =
              request.checkoutInfo.email || (billingDetails?.email as string)

            let confirmResult: ConfirmPaymentResultEvent | undefined

            if (card?.brand !== 'AMEX') {
              await spiSdk.confirmPayment(
                async (confirmResponse: ConfirmPaymentResultEvent) => {
                  confirmResult = confirmResponse
                },
                () => {
                  toastError('Something went wrong.')
                  request.header.response$.next({
                    kind: ResponseTypes.ERROR,
                    message: 'An error has occurred',
                    code: ''
                  })
                },
                { email }
              )
            }
            try {
              const res = await incAuthAndFire({
                variables: {
                  addIncAuthAndFire: {
                    partyGuid,
                    partyMemberGuid,
                    memberAuthToken,
                    restaurantGuid,
                    // customer info either comes from tabs-spa form or from apple/google pay billing details
                    customer: {
                      email,
                      firstName:
                        request.checkoutInfo.firstName ||
                        billingDetails?.name.split(' ')[0],
                      lastName:
                        request.checkoutInfo.lastName ||
                        billingDetails?.name.split(' ')[1],
                      phone:
                        getRawPhoneNumber(request.checkoutInfo.phone) ||
                        billingDetails?.phoneNumber
                    },
                    incrementAuthData: {
                      paymentIntentId:
                        paymentIntent.sessionSecret.split('_')[1],

                      externalReferenceId: confirmResult
                        ? confirmResult.content.payment.externalReferenceId
                        : uuid(),
                      paymentMethodId: paymentMethod.content.paymentMethodId,
                      spiPaymentType: paymentMethod.content.paymentMethodType,
                      lastIncrement: false
                    },

                    spiCardInfo: {
                      cardType:
                        card?.brand ||
                        confirmResult!.content.payment.latestPayment.card.brand,
                      last4CardDigits:
                        card?.lastFour ||
                        confirmResult!.content.payment.latestPayment.card
                          .lastFour,
                      cardHolderExpirationMonth:
                        card?.expiry.month ||
                        confirmResult!.content.payment.latestPayment.card.expiry
                          .month,
                      cardHolderExpirationYear:
                        card?.expiry.year ||
                        confirmResult!.content.payment.latestPayment.card.expiry
                          .year
                    }
                  }
                }
              })

              const { OPTPartyError, OPTPartyRefreshV2 } = dataByTypename(
                res.data?.addIncAuthAndFire
              )

              if (OPTPartyError) {
                const { code } = OPTPartyError
                const errorMsg = getErrorMsg(code)

                toastError(errorMsg)

                captureMessage(OPTPartyError.message, 'warning', {
                  captureContext: (scope: any) =>
                    scope.setTags({ source: 'corn-tabs-request' })
                })

                request.header.response$.next({
                  kind: ResponseTypes.ERROR,
                  message: 'An error has occurred',
                  code
                })
              }

              if (OPTPartyRefreshV2) {
                request.header.response$.next({
                  kind: ResponseTypes.OK,
                  info: [{ code: '200', message: 'Paid with SPI' }],
                  warnings: []
                })
              }
            } catch (err: any) {
              toastError('Something went wrong.')
              captureMessage(err.message, 'warning', {
                captureContext: (scope: any) =>
                  scope.setTags({ source: 'corn-tabs-request' })
              })

              request.header.response$.next({
                kind: ResponseTypes.ERROR,
                message: 'An error has occurred',
                code: ''
              })
            }
          },
          () => {
            toastError('Something went wrong.')
            request.header.response$.next({
              kind: ResponseTypes.ERROR,
              message: 'An error has occurred',
              code: ''
            })
          },
          [
            {
              label: 'Subtotal',
              // @ts-ignore
              amount: updateIntentData.updatePaymentIntent.intentAmount / 100
            }
          ]
        )
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [
    partyGuid,
    updateIntentLoading,
    updateIntentData,
    partyMemberGuid,
    memberAuthToken,
    paymentIntent,
    restaurantGuid,
    spiSdk,
    paymentIntent?.sessionSecret,
    incAuthAndFire,
    captureMessage
  ])

  React.useEffect(() => {
    if (applePayFired && !applePayLoading) {
      const { OPTPartyError } = dataByTypename(
        applePayResponse?.data?.optAddPreauthAndFire
      )

      if (applePayError || OPTPartyError) {
        const message = applePayError
          ? applePayError.message
          : OPTPartyError.message
        toastError(message || 'Something went wrong. Please try again.')

        if (OPTPartyError) {
          captureMessage(OPTPartyError.message, 'warning', {
            captureContext: (scope: any) =>
              scope.setTags({ source: 'corn-tabs-request' })
          })
        }

        response$Ref.current?.next({
          kind: ResponseTypes.ERROR,
          message: 'An error has occurred',
          code: ''
        })
      } else {
        response$Ref.current?.next({
          kind: ResponseTypes.OK,
          info: [{ code: '200', message: 'Paid with Apple Pay' }],
          warnings: []
        })
      }

      setApplePayFired(false)
    }
  }, [
    applePayFired,
    applePayLoading,
    applePayError,
    applePayResponse,
    captureMessage
  ])

  return <></>
}
