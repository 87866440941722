import { useState, useEffect, useRef, useCallback } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { useValidate_Apple_Pay_MerchantMutation } from '../../../apollo/generated/OptWebGraphQLOperations'
import { useDDIGlobals } from '../../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import {
  makeApplePayPayment,
  validateApplePayContactFields
} from '../../../utils/apple-pay/apple-pay-utils'
import { getRawPhoneNumber } from '../../../utils/form-utils'
import { useRestaurantInfo } from '../../restaurant-info/use-restaurant-info'
import { useLineItemsDeferred } from '../../../utils/check-helpers'
import { HandlePlaceOrderValuesV2 } from '../../../utils/checkout-helpers'
import { CheckoutMode } from '../../../components/CheckoutForm/checkout-modes'
import ApplePayStrategy from './applePayStrategy'
import { track } from '@toasttab/do-secundo-analytics'
import { useFlag } from '../../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'
import { useSentry } from 'banquet-runtime-modules'

function isErrorLike(e: unknown): e is GenericError {
  return Boolean(get(e, 'message'))
}

function toErrorLike(e: unknown, defaultMessage: string): GenericError {
  if (isErrorLike(e)) {
    return e
  }
  return {
    message: defaultMessage
  }
}

function passThroughErrorMessage(e: GenericError): string {
  return e.message
}

interface GenericError {
  message: string
}

type CheckLineItemsList = ReturnType<ReturnType<typeof useLineItemsDeferred>>
function lineItemsToApplePayLineItems(
  lineItems: CheckLineItemsList
): ApplePayJS.ApplePayLineItem[] {
  return lineItems.map((li) => {
    return {
      amount: li.amount.toFixed(2),
      label: li.label
    }
  })
}

const onErrorDefault = () => {}
export const useHandleApplePayPayment = ({
  onError = onErrorDefault,
  submitFunction,
  mode
}: {
  onError?(error: GenericError): void
  submitFunction(values: HandlePlaceOrderValuesV2): Promise<any>
  mode: CheckoutMode
}) => {
  const { captureMessage } = useSentry()
  const removeApplePayShippingEnabled = useFlag(
    LDFlags.OPT_REMOVE_APPLE_PAY_SHIPPING
  )
  const [applePayMerchantMutate] = useValidate_Apple_Pay_MerchantMutation()
  const { restaurantGuid } = useDDIGlobals()
  const { data } = useRestaurantInfo()
  const { creditCardConfig, whiteLabelName } = data ?? {}
  const getLineItems = useLineItemsDeferred()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<GenericError | null>(null)
  const [paymentResponse, setPaymentResponse] = useState<any>(null)
  const handleError = useCallback(
    (
      e: unknown,
      buildUserFacingMessage: (
        e: GenericError
      ) => string = passThroughErrorMessage
    ) => {
      const error = toErrorLike(e, 'An unknown error occurred with Apple Pay.')
      // log in sentry
      captureMessage(error.message, 'error')
      // use analytics to track as a fallback/re-validation point
      track('Apple Pay Error', { message: error.message })
      // sets error state to propogate to hook consumer
      setErrorMessage({
        message: `${buildUserFacingMessage(
          error
        )} Please try again or use the credit card option.`
      })
      setLoading(false)
    },
    [captureMessage]
  )

  // avoid requiring consumer to make stabilize onError
  const onErrorRef = useRef(onError)
  onErrorRef.current = onError
  useEffect(() => {
    if (errorMessage) {
      onErrorRef.current(errorMessage)
    }
  }, [errorMessage])

  return {
    initApplePayPayment(values: HandlePlaceOrderValuesV2): any {
      setLoading(true)
      setErrorMessage(null)
      // steps to get check value from passed-in values
      const applePayValues = (function (): {
        amountToPay: string
        lineItems: CheckLineItemsList
        whiteLabelName: string
        restaurantGuid: string
      } {
        try {
          const check = values.context?.checkToPayFor
          const tipAmount = values.paymentInfo.tipAmount
          const applePayStrat = ApplePayStrategy.getApplePayStrategy(mode)
          const { amountToPay, lineItems } = applePayStrat(
            check,
            tipAmount,
            getLineItems
          )
          if (isNil(amountToPay)) {
            throw new Error('Apple Pay - amountToPay not supplied.')
          }
          const floatAmountToPay = parseFloat(amountToPay)
          if (isNaN(floatAmountToPay) || floatAmountToPay < 0) {
            throw new Error('Apple Pay - amountToPay is invalid.')
          }
          if (!whiteLabelName || !restaurantGuid) {
            throw new Error('Missing restaurant info.')
          }
          return { amountToPay, lineItems, whiteLabelName, restaurantGuid }
        } catch (e) {
          handleError(e, () => 'Invalid Apple Pay attempt.')
          // re-throw the error and abort
          throw e
        }
      })()

      try {
        const supportedNetworks = ['discover', 'masterCard', 'visa']
        if (creditCardConfig?.amexAccepted) {
          supportedNetworks.push('amex')
        }

        const defaultConfig = {
          currencyCode: 'USD',
          countryCode: 'US',
          total: {
            label: applePayValues.whiteLabelName,
            amount: applePayValues.amountToPay
          },
          requiredBillingContactFields: ['postalAddress'],
          requiredShippingContactFields: ['name', 'phone', 'email'],
          lineItems: lineItemsToApplePayLineItems(applePayValues.lineItems),
          supportedNetworks,
          merchantCapabilities: ['supports3DS']
        }

        const noShippingConfig = {
          currencyCode: 'USD',
          countryCode: 'US',
          total: {
            label: applePayValues.whiteLabelName,
            amount: applePayValues.amountToPay
          },
          requiredBillingContactFields: ['postalAddress', 'name'],
          requiredShippingContactFields: ['phone', 'email', 'name'],
          lineItems: lineItemsToApplePayLineItems(applePayValues.lineItems),
          supportedNetworks,
          merchantCapabilities: ['supports3DS']
        }

        return makeApplePayPayment({
          // @ts-ignore apple pay doesnt export the type
          config: removeApplePayShippingEnabled
            ? noShippingConfig
            : defaultConfig,
          async getMerchantSession(validationURL) {
            try {
              const mutationResponse = await applePayMerchantMutate({
                variables: {
                  input: {
                    // TODO:  Will this work once hostname changes?
                    merchantDomain: window.location.hostname,
                    validationURL,
                    restaurantGuid: applePayValues.restaurantGuid
                  }
                }
              })
              const response = mutationResponse?.data?.validateApplePayMerchant
              if (
                response?.__typename ===
                'ValidateApplePayMerchantSuccessResponse'
              ) {
                return JSON.parse(response.merchantSession)
              } else if (
                response?.__typename === 'ValidateApplePayMerchantError'
              ) {
                // throws so network + reponse errors are handled similarly
                throw new Error(`[${response.code}]: ${response.message}`)
              }
            } catch (e) {
              handleError(e, () => `Unable to validate merchant.`)
              // KNOWN ISSUE: -- For some reason, AppleJS will handle this error as a 'cancel' and will therefore
              // trigger the onCancel callback. Therfore, the user-facing issue will be obscured. There is no
              // useful metadata on the cancel event that allows us to identify this error.

              // return empty object, which will force a failure in the completeMerchantValidation callback
              return {}
            }
          },
          async performTransaction(payment) {
            try {
              values.paymentInfo.pkPaymentToken = JSON.stringify(payment)
              const { shippingContact, billingContact } = payment
              if (shippingContact) {
                shippingContact.phoneNumber = getRawPhoneNumber(
                  shippingContact?.phoneNumber
                )
              }

              await validateApplePayContactFields({
                emailAddress: shippingContact?.emailAddress,
                familyName:
                  shippingContact?.familyName || billingContact?.familyName,
                givenName:
                  shippingContact?.givenName || billingContact?.givenName,
                phoneNumber: shippingContact?.phoneNumber
              })
              // Note: requiredShippingContactFields should ensure these fields
              // will exist, but checking required by type system
              if (shippingContact && shippingContact.emailAddress) {
                values.paymentInfo.customer = {
                  email: shippingContact.emailAddress,
                  firstName:
                    shippingContact.givenName ||
                    billingContact?.givenName ||
                    '',
                  lastName:
                    shippingContact.familyName ||
                    billingContact?.familyName ||
                    '',
                  phone: getRawPhoneNumber(shippingContact.phoneNumber)
                }
              }
              setPaymentResponse(await submitFunction(values))
            } catch (e) {
              handleError(e)
            } finally {
              setLoading(false)
            }
          },
          initSession() {},
          onCancel() {
            handleError({
              message: 'Apple Pay cancelled.'
            })
          }
        })
      } catch (e) {
        handleError(e)
      }
    },
    error: errorMessage,
    loading,
    response: paymentResponse
  }
}
